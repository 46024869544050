import {
  eventTypes,
} from '@/constants'

const PARENT_FRONT_URL = process.env.VUE_APP_PARENT_FRONT_URL || 'https://platform.testing.datrics.ai'
const TARGET_ORIGIN = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : PARENT_FRONT_URL

export const useIframeMessaging = ({
  isDisabled,
  conversationUuid,
  handleGetConversation,
  handleGetAiAnalystItem,
}) => {
  const sendMessageToParent = (message) => {
    if (!window.parent) return;
    parent.postMessage(message, TARGET_ORIGIN)
  }

  const initMessageListeners = () => {
    window.addEventListener('message', async function(event) {
      if (!event.origin.includes('datrics') && !event.origin.includes('localhost') && !event.origin.includes('netlify.app')) return;

      if (event.data === eventTypes.AI_ANALYST_REFRESH_IFRAME) {
        await handleGetAiAnalystItem()
        await handleGetConversation({ conversation_uuid: conversationUuid.value })
      }
      if (event.data === eventTypes.AI_ANALYST_DISABLE_IFRAME) {
        isDisabled.value = true
      }
      if (event.data === eventTypes.AI_ANALYST_ENABLE_IFRAME) {
        isDisabled.value = false
      }
      // Handle the message
      // console.log('Message received in iframe:', event.data);
    }, false);
  }

  const destroyMessageListeners = () => {
    window.removeEventListener('message');
  }

  return {
    initMessageListeners,
    destroyMessageListeners,
    sendMessageToParent,
  }
}